<script setup lang="ts">
  import { ChevronRightIcon } from '@heroicons/vue/solid';

  const props = defineProps<{
    to: string;
    label: string;
  }>();

  const isActive = computed(() => {
    return useRoute().path === props.to;
  });
</script>

<template>
  <NuxtLink
    :to="props.to"
    class="flex justify-between items-center text-zinc-600 sm-medium p-2 hover:bg-zinc-100 rounded-md border group"
    :class="
      isActive
        ? 'bg-blue-50 border-blue-200 font-bold text-blue-800 shadow-[0_2px_2px_0_#00000014] hover:bg-blue-50'
        : 'border-transparent'
    "
  >
    {{ label }}
    <ChevronRightIcon
      class="text-zinc-400 w-5 h-5 group-hover:opacity-100 transition-opacity"
      :class="isActive ? 'text-blue-500 opacity-100' : 'opacity-0'"
    />
  </NuxtLink>
</template>
